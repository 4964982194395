import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'shelves',
      value: false,
      name: 'switch',
      label: i18n.t('form.coupon.online'),
      Object: {}
    }, {
      span: 24,
      prop: 'eligible_for_dine_in',
      value: false,
      name: 'switch',
      label: i18n.t('form.coupon.dineIn'),
      Object: {
        client: 'fooyo'
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.coupon.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'collection_method',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.receiveType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        label: 'label'
      }
    }, {
      span: 8,
      prop: 'price_f',
      name: 'input',
      label: i18n.t('form.coupon.price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'collection_method',
        visibleValue: 'paid'
      }
    }, {
      span: 8,
      prop: 'shipping_method',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.deliveryType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        label: 'label'
      }
    }, {
      span: 8,
      prop: 'coupon_type',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.type'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        filterable: true,
        label: 'label'
      }
    }, {
      span: 8,
      prop: 'discount_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.discountAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'coupon_type',
        visibleValue: 'amount'
      }
    }, {
      span: 8,
      prop: 'discount_percent',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.couponDiscout'),
      placeholder: i18n.t('placeholder.discount'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'coupon_type',
        visibleValue: 'rebate'
      }
    }, {
      span: 8,
      prop: 'max_discount_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.maxDiscount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'coupon_type',
        visibleValue: 'rebate'
      }
    }, {
      span: 8,
      prop: 'target_amount_f',
      name: 'input',
      label: i18n.t('form.coupon.useDoor'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'amount_per_user',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.getTimes'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'stock',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.stock'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'valid_type',
      name: 'select',
      label: i18n.t('form.coupon.validityType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        label: 'label'
      }
    }, {
      span: 8,
      prop: 'valid_days',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.validDays'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'valid_type',
        visibleValue: 'by_duration'
      }
    }, {
      span: 8,
      prop: 'valid_date',
      name: 'date',
      label: i18n.t('form.coupon.startDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        visible: 'valid_type',
        visibleValue: 'by_date'
      }
    }, {
      span: 8,
      prop: 'expire_date',
      name: 'date',
      label: i18n.t('form.coupon.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        visible: 'valid_type',
        visibleValue: 'by_date'
      }
    }, {
      span: 24,
      prop: 'description_1',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.coupon.simpleDsc'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        row: 4
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.coupon.describe'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        row: 8
      }
    }, {
      span: 24,
      prop: 'usage',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.coupon.instructions'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        row: 3
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.coupon.img'),
      Object: {
        limit: 100,
        imageName: 'url',
        size: `${i18n.t('form.product.recommendedSize')}: 120x120`
      }
    }, {
      span: 8,
      prop: 'couponable_type',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.objectType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        nextChange: true,
        nextChangeProp: 'couponable_type',
        label: 'label'
      }
    }, {
      span: 8,
      prop: 'poi_ids',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.coupon.applicationObject'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        nextChange: true,
        nextChangeProp: 'poi_ids',
        key: 'id',
        value: 'id',
        filterable: true,
        multiple: true,
        'collapse-tags': true,
        visible: 'couponable_type',
        visibleValue: 'Shop,Site',
        isAll: true,
        isAllValue: true
      }
    }, {
      span: 8,
      prop: 'shop_id',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.applicationObject'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        nextChange: true,
        nextChangeProp: 'shop_id',
        key: 'id',
        value: 'id',
        filterable: true,
        visible: 'couponable_type',
        visibleValue: 'ShopItem'
      }
    }, {
      span: 8,
      prop: 'apply_to',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.applyTo'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        label: 'label',
        filterable: true,
        visible: 'couponable_type',
        visibleValue: 'ShopItem',
        nextChange: true,
        nextChangeProp: 'apply_to'
      }
    }]
  }]
}
