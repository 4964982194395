// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getOnlineCouponsConfigurations, getOnlineCouponsShopList, getOnlineCouponsShopProduct, onlineCouponAdd, getSiteSimpList } from '@/services/coupon.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.confirm'),
      setupActive: 0,
      isNext: false,
      sites: [],
      applyToOptions: [
        {
          label: this.$t('form.coupon.entireShop'),
          value: 'entire_shop'
        },
        {
          label: this.$t('form.coupon.selectedProducts'),
          value: 'selected_products'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getOnlineCouponsConfigurations().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[21].options = res.data.couponable_types
          this.formData[0].createDateItem[6].options = res.data.coupon_types
          this.formData[0].createDateItem[5].options = res.data.shipping_methods
          this.formData[0].createDateItem[13].options = res.data.valid_types
          this.formData[0].createDateItem[3].options = res.data.collection_methods
          this.formData[0].createDateItem[24].options = this.applyToOptions
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      if (prop === 'couponable_type') {
        if (data === 'Shop' || data === 'ShopItem') {
          this.getShops(data)
        }
        if (data === 'Site') {
          this.getSites()
        }
      }
      if (prop === 'apply_to') {
        if (data === 'selected_products') {
          this.btnTxt = this.$t('button.next')
          this.isNext = true
        } else {
          this.btnTxt = this.$t('button.confirm')
          this.isNext = false
        }
      }
    },
    getSites () {
      getSiteSimpList().then(res => {
        if (res.status === 200) {
          res.data.sites.unshift({ id: 0, name: this.$t('form.coupon.all') })
          this.formData[0].createDateItem[22].options = res.data.sites
        }
      })
    },
    getShops (type) {
      getOnlineCouponsShopList().then(res => {
        if (res.status === 200) {
          if (type === 'Shop') {
            res.data.shops.unshift({ id: 0, name: this.$t('form.coupon.all') })
          }
          this.formData[0].createDateItem[22].options = res.data.shops
          this.formData[0].createDateItem[23].options = res.data.shops
        }
      })
    },
    getProduct (shop_id) {
      const params = {
        shop_id,
        all: true,
        only_name: true
      }
      getOnlineCouponsShopProduct(params).then(res => {
        res.data.shop_items.unshift({ id: 0, name: this.$t('form.coupon.all') })
        this.formData[0].createDateItem[15].options = res.data.shop_items
        console.log(this.formData)
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data.data))
      if (obj.couponable_type === 'ShopItem') {
        if (!obj.shop_id) {
          this.$store.state.app.loading.close()
          this.$message.error(this.$t('text.require', { name: this.$t('form.coupon.chooseShop') }))
          return
        }
        if (!obj.apply_to) {
          this.$store.state.app.loading.close()
          this.$message.error(this.$t('text.require', { name: this.$t('form.coupon.applyTo') }))
          return
        }
      }
      if (obj.apply_to === 'entire_shop') {
        obj.poi_ids = [0]
      }
      delete obj.apply_to
      console.log(obj)
      const params = {
        online_coupon: obj
      }
      onlineCouponAdd(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          // this.$message({
          //   type: 'success',
          //   message: this.$t('message.operateSuccess')
          // })
          if (this.btnTxt === this.$t('button.confirm')) {
            setTimeout(() => {
              this.$router.push({
                name: 'couponManagerOnlineCouponList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          } else {
            this.setupActive = 1
            this.$router.push({
              name: 'couponProductList',
              query: {
                id: res.data.coupon.id,
                shop_id_eq: obj.shop_id
              }
            })
          }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.back(-1)
    }
  }
}
